<template>
  <section class="w-form-card" :class="{ 'w-form-card--rotate': rotating }">
    <header>
      <h3 class="w-form-card__title">
        {{ $t("title.project_details") }}
      </h3>
    </header>
    <!-- <b-container fluid> -->
    <form-wrapper :validator="$v.form">
      <b-form class="form-box form-box--mini">
        <form-group
          label-for="project_name"
          attribute="input.project_name"
          name="project_name"
        >
          <template slot-scope="{ attrs }">
            <b-form-input
              v-model.trim="form.project_name"
              class="form-box__input"
              v-bind="attrs"
              type="text"
              :disabled="!isActive"
              :placeholder="$t('input.project_name')"
              @input="$v.form.project_name.$touch()"
            />
          </template>
        </form-group>

        <form-group label-for="note" attribute="input.note" name="note">
          <template slot-scope="{ attrs }">
            <b-form-input
              v-model.trim="form.note"
              class="form-box__input"
              v-bind="attrs"
              type="text"
              :disabled="!isActive"
              :placeholder="$t('input.note')"
              @input="$v.form.note.$touch()"
            />
          </template>
        </form-group>

        <form-group
          label-for="example"
          attribute="input.example"
          name="example"
        >
          <template slot-scope="{ attrs }">
            <b-form-textarea
              v-model.trim="form.example"
              class="form-box__input"
              v-bind="attrs"
              type="text"
              rows="3"
              :disabled="!isActive"
              :placeholder="$t('input.example')"
              @input="$v.form.example.$touch()"
            />
          </template>
        </form-group>

        <!-- <multiImageUpload /> -->
      </b-form>
    </form-wrapper>
    <!-- </b-container> -->

    <section class="w-form-card__actions">
      <b-button
        class="button button--filled"
        :disabled="$v.$invalid"
        @click="save"
        >{{ $t("button.next") }}</b-button
      >
    </section>

    <!-- Disability Layer -->
    <section v-if="!isActive" class="w-form-card__overlay--disabled"></section>
    <!-- Disability Layer -->

    <section
      class="w-form-card__overlay"
      :class="{ 'w-form-card__overlay--show': editMode }"
    >
      <section class="w-form-card__overlay__body">
        <h4 class="w-form-card__overlay__header">
          {{ $t("label.project_details") }} :
        </h4>
        <h5
          v-for="(projKey, index) in formKeys"
          :key="index"
          class="w-form-card__overlay__body"
        >
          {{ $t(`label.${projKey}`) }} : {{ form[projKey] }}
        </h5>
      </section>
      <b-button
        v-if="!disableEdits"
        class="button button--outline-white w-form-card__overlay__button"
        @click="edit"
        >{{ $t("button.edit") }}</b-button
      >
    </section>
  </section>
</template>

<script>
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import { RotatingCard } from "@/constants/Mixins/RotatingCard";

export default {
  name: "ProjectDetails",
  mixins: [RotatingCard],

  props: {
    isActive: {
      type: Boolean,
      required: true
    },
    disableEdits: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: {
        project_name: "",
        note: "",
        example: ""
      },
      formKeys: []
    };
  },
  methods: {
    save() {
      this.formKeys = [...Object.keys(this.form)];
      this.$emit("projectDetailed", this.form);
      this.rotate();
    },
    edit() {
      this.$emit("projectDetailed", {});
      this.rotateBack();
    }
  },

  validations() {
    return {
      form: {
        project_name: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(50)
        },
        note: {
          minLength: minLength(3),
          maxLength: maxLength(50)
        },
        example: {
          minLength: minLength(3),
          maxLength: maxLength(255)
        }
      }
    };
  }
};
</script>
